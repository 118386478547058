<template>
  <section class="invoice-preview-wrapper">

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="orderData === undefined"
    >
      <h4 class="alert-heading">
        Error fetching order data
      </h4>
      <div class="alert-body">
        No order found with this order id. Check
        <b-link
          class="alert-link"
          :to="{ name: 'apps-order-list'}"
        >
          Order List
        </b-link>
        for other orders.
      </div>
    </b-alert>

    <b-row
      v-if="orderData"
      class="invoice-preview"
    >

      <!-- Col: Left (Order Container) -->
      <b-col
        cols="12"
        xl="12"
        md="12"
      >
        <b-card
          no-body
          class="invoice-preview-card"
        >
          <!-- Header -->
          <b-card-body class="invoice-padding pb-0">
            <div class="d-flex justify-content-between flex-md-row flex-column order-spacing mt-0">

              <!-- Header: Left Content -->
              <order-header-left-content />

              <!-- Header: Right Content -->
              <div class="mt-md-1 mt-2">
                <h5 class="invoice-title">
                  Reference
                  <span class="invoice-number"># {{ orderData.referenceNumber }}</span>
                </h5>
                <div class="invoice-date-wrapper">
                  <p class="invoice-date-title">
                    Issued Date:
                  </p>
                  <p class="invoice-date">
                    {{ orderData.issuedDate | formatDate }}
                  </p>
                </div>
                <div class="invoice-date-wrapper">
                  <p class="invoice-date-title">
                    Service Date:
                  </p>
                  <p class="invoice-date">
                    {{ orderData.serviceDate | formatDate }}
                  </p>
                </div>
                <div
                  v-show="orderData.servicePerson"
                  class="invoice-date-wrapper"
                >
                  <p class="invoice-date-title">
                    Installer:
                  </p>
                  <p class="invoice-date">
                    {{ orderData.servicePerson }}
                  </p>
                </div>
                <div
                  v-show="orderData.referralSource"
                  class="invoice-date-wrapper"
                >
                  <p class="invoice-date-title">
                    Referral:
                  </p>
                  <p class="invoice-date">
                    {{ orderData.referralSource }}
                  </p>
                </div>
                <div
                  v-if="orderData.signature.isRequired && orderData.signature.signedDate"
                  class="invoice-date-wrapper"
                >
                  <p class="invoice-date-title">
                    Signed Date:
                  </p>
                  <p class="invoice-date">
                    {{ orderData.signature.signedDate | formatDate }}
                  </p>
                </div>
              </div>
            </div>
          </b-card-body>

          <!-- Spacer -->
          <hr class="invoice-spacing">

          <!-- Order Client & Payment Details -->
          <b-card-body
            v-if="orderData.client"
            class="invoice-padding pt-0"
          >
            <b-row class="invoice-spacing">

              <!-- Col: Order To -->
              <b-col
                cols="12"
                xl="6"
                class="p-0"
              >
                <h6 class="mb-2">
                  Customer Information:
                </h6>
                <h6 class="mb-25">
                  {{ orderData.client.firstName }} {{ orderData.client.lastName }}
                </h6>
                <p class="card-text mb-25">
                  {{ orderData.client.address }}, {{ orderData.client.city }}, {{ orderData.client.postalCode }}, {{ orderData.client.country }}
                </p>
                <p class="card-text mb-25">
                  {{ orderData.client.phoneNumber }}
                </p>
                <p class="card-text mb-0">
                  {{ orderData.client.email }}
                </p>
              </b-col>

              <!-- Col: Payment Details -->
              <b-col
                xl="6"
                cols="12"
                class="p-0 mt-xl-0 mt-2 d-flex justify-content-xl-end"
              >
                <div>
                  <h6 class="mb-2">
                    Payment Details:
                  </h6>
                  <table>
                    <tbody>
                      <tr>
                        <td class="pr-1">
                          Total Due:
                        </td>
                        <td><span class="font-weight-bold">${{ orderData.paymentDetails.balance }}</span></td>
                      </tr>
                      <tr>
                        <td class="pr-1">
                          Payment Method:
                        </td>
                        <td>{{ orderData.paymentDetails.paymentMethod }}</td>
                      </tr>

                    </tbody>
                  </table>
                </div>
              </b-col>
            </b-row>
          </b-card-body>

          <!-- Order Description: Table -->
          <b-table-lite
            responsive
            :items="orderData.items"
            :fields="[{key: 'thumbnail', label: ''}, 'item', {key: 'product.displayName', label: 'MODEL'}, 'description', 'price', 'quantity']"
          >
            <template #cell(thumbnail)="data">
              <img
                :src="data.item.product.thumbnail"
                style="height:50px"
              >
            </template>

          </b-table-lite>

          <!-- Order Description: Total -->
          <b-card-body class="invoice-padding pb-0">
            <b-row>

              <!-- Col: Salesperson -->
              <b-col
                cols="12"
                md="6"
                class="mt-md-0 mt-3"
                order="2"
                order-md="1"
              >
                <b-card-text class="mb-0">
                  <span class="font-weight-bold">Salesperson:</span>
                  <span class="ml-75">{{ `${orderData.salesperson.firstName} ${orderData.salesperson.lastName}` }}</span>
                </b-card-text>
              </b-col>

              <!-- Col: Total -->
              <b-col
                cols="12"
                md="6"
                class="mt-md-6 d-flex justify-content-end"
                order="1"
                order-md="2"
              >
                <div
                  v-if="orderData.paymentDetails"
                  class="invoice-total-wrapper"
                >
                  <div class="invoice-total-item">
                    <p class="invoice-total-title">
                      Subtotal:
                    </p>
                    <p class="invoice-total-amount">
                      ${{ orderData.paymentDetails.subtotal }}
                    </p>
                  </div>
                  <div class="invoice-total-item">
                    <p class="invoice-total-title">
                      Tax:
                    </p>
                    <p class="invoice-total-amount">
                      ${{ orderData.paymentDetails.tax }}
                    </p>
                  </div>
                  <hr class="my-50">
                  <div class="invoice-total-item">
                    <p class="invoice-total-title">
                      Total:
                    </p>
                    <p class="invoice-total-amount">
                      ${{ orderData.paymentDetails.total }}
                    </p>
                  </div>
                  <div class="invoice-total-item">
                    <p class="invoice-total-title">
                      Deposit:
                    </p>
                    <p class="invoice-total-amount">
                      ${{ orderData.paymentDetails.deposit }}
                    </p>
                  </div>
                  <div class="invoice-total-item">
                    <p class="invoice-total-title">
                      Balance:
                    </p>
                    <p class="invoice-total-amount">
                      ${{ orderData.paymentDetails.balance }}
                    </p>
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-card-body>

          <!-- Spacer -->
          <hr class="invoice-spacing">

          <!-- Note -->
          <b-card-body class="invoice-padding pt-0">
            <span class="font-weight-bold">Note: </span>
            <div v-html="orderData.notes" />
          </b-card-body>

          <!-- Agreement -->
          <b-card-body
            v-if="orderData.signature.isRequired && !orderData.signature.isSigned && showSignatureControl"
            class="invoice-padding pt-0"
          >
            <div v-if="orderData.signature.agreementLinks && orderData.signature.agreementLinks.length > 0">
              <ul id="agreements">
                <li
                  v-for="agreement in orderData.signature.agreementLinks"
                  :key="agreement.link"
                >
                  <h6>{{ agreement.title }}</h6>
                  <b-form-checkbox
                    v-if="agreement.title && agreement.url"
                    v-model="agreement.signed"
                    value="A"
                    class="custom-control-info"
                  >
                    <span class="font-weight-bold">I agreed</span> to the terms and conditions of the
                    <a
                      :href="agreement.url"
                      target="_blank"
                    >
                      {{ agreement.title }}
                    </a>
                  </b-form-checkbox>
                </li>
              </ul>
              <br>
              <div>
                <!-- card 3 -->
                <b-col
                  md="6"
                  lg="4"
                >
                  <b-card
                    title="Signature"
                    footer="please sign in the box"
                    class="text-center"
                    footer-class="text-muted"
                  >

                    <VueSignaturePad
                      ref="signaturePad"
                      width="300px"
                      height="100px"
                    />
                    <div>
                      <button @click="save">
                        Save
                      </button>
                      <button @click="undo">
                        Undo
                      </button>
                    </div>
                  </b-card>
                  <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    :disabled="orderData.signature.agreementLinks.find(a => a.signed === false) || signatureData.isEmpty"
                    type="submit"
                    variant="outline-primary"
                    @click="signOrder(signatureData)"
                  >
                    Submit
                  </b-button>
                </b-col>
              </div>
              <br>
              <!-- all agreements must be checked before sending the signature -->
            </div>
            <div v-else>
              <div>
                <b-col
                  md="5"
                  lg="3"
                >
                  <b-card
                    title="Signature"
                    footer="please sign in the box"
                    class="text-center"
                    footer-class="text-muted"
                  >

                    <VueSignaturePad
                      ref="signaturePad"
                      width="300px"
                      height="100px"
                    />
                    <div>
                      <button @click="save">
                        Save
                      </button>
                      <button @click="undo">
                        Undo
                      </button>
                    </div>
                  </b-card>
                  <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    type="submit"
                    :disable="!signatureData.isEmpty"
                    variant="outline-primary"
                    @click="signOrder(signatureData)"
                  >
                    Submit
                  </b-button>
                </b-col>
              </div>
            </div>
          </b-card-body>
        </b-card>
        <b-card v-if="orderData.signature.isRequired">
          <b-card-body class="invoice-padding pt-0">
            <h5>Signature Audit Trail</h5>
            <b-badge
              href="#"
              variant="primary"
              @click="copySignatureRequestLink(`/apps/order/preview/${orderData.id}?showSignatureControl=true&token=token`)"
            >
              <feather-icon
                icon="LinkIcon"
                class="mr-25"
              />
              <span>External Preview Link</span>
            </b-badge>
            <div
              v-for="k in Object.keys(orderData.signature)"
              :key="k"
            >
              <div>
                <strong>{{ k | formatKey }}:</strong>
                <div v-if="typeof orderData.signature[k] === 'string' && orderData.signature[k].startsWith('data:image/png;base64')">
                  <br>
                  <img :src="orderData.signature[k]">
                </div>
                <div v-else>
                  <div v-if="typeof orderData.signature[k] === 'string'">
                    {{ orderData.signature[k] | formatValue }}
                  </div>
                  <div v-else>
                    <div v-if="Array.isArray(orderData.signature[k])">
                      <ul>
                        <li
                          v-for="agreementLink in orderData.signature[k]"
                          :key="agreementLink.title"
                        >
                          <a
                            target="_blank"
                            :href="agreementLink.url"
                          >{{ agreementLink.title }} </a>
                        </li>
                      </ul>
                    </div>
                    <div v-else>
                      {{ orderData.signature[k] | formatValue }}
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </b-card-body>
        </b-card>
        <b-card v-if="orderData.status !== 'completed'">
          <b-card-body>

            <b-alert
              v-show="orderData.status !== 'completed' "
              show
              variant="warning"
            >
              <div class="alert-body">
                <feather-icon
                  icon="InfoIcon"
                  class="mr-50 mt-25"
                />
                Status: <b-badge variant="warning">
                  {{ orderData.status }}
                </b-badge>
                <br>
                This is not an invoice (id: {{ orderData.id }})
                <br>
                This is a preliminary estimate.
              </div>
            </b-alert>
          </b-card-body>
        </b-card>
      </b-col>
      <!-- Right Col: Card -->
      <b-col
        v-if="showControl"
        cols="12"
        md="4"
        xl="3"
        class="invoice-actions"
      >
        <b-card>

          <!-- Button: Send Order -->
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            v-b-toggle.sidebar-send-order
            variant="primary"
            class="mb-75"
            block
          >
            Send Order
          </b-button>

          <!-- Button: Download -->
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            class="mb-75"
            block
          >
            Download
          </b-button>

          <!-- Button: Print -->
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            class="mb-75"
            block
            @click="printOrder"
          >
            Print
          </b-button>

          <!-- Button: Edit -->
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            class="mb-75"
            block
            :to="{ name: 'apps-order-edit', params: { id: $route.params.id } }"
          >
            Edit
          </b-button>

          <!-- Button: Add Payment -->
          <b-button
            v-b-toggle.sidebar-order-add-payment
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="success"
            class="mb-75"
            block
          >
            Add Payment
          </b-button>
        </b-card>
      </b-col>
    </b-row>

    <order-sidebar-send-order />
    <order-sidebar-add-payment />
  </section>
</template>

<script>
import { ref, onUnmounted } from '@vue/composition-api'
import store from '@/store'
import router from '@/router'
import {
  BRow, BCol, BCard, BCardBody, BTableLite, BCardText, BAlert, BLink, VBToggle, BFormCheckbox, BBadge,
  BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { useClipboard } from '@vueuse/core'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useToast } from 'vue-toastification/composition'
import useOrderHandler from '../useOrderHandler'
import OrderStoreModule from '../OrderStoreModule'
import OrderSidebarSendOrder from '../OrderSidebarSendOrder.vue'
import OrderHeaderLeftContent from '../OrderHeaderLeftContent.vue'
import OrderSidebarAddPayment from '../OrderSidebarAddPayment.vue'

export default {
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  components: {
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    OrderHeaderLeftContent,
    BRow,
    BBadge,
    BCol,
    BCard,
    BCardBody,
    BTableLite,
    BCardText,
    BAlert,
    BLink,
    OrderSidebarAddPayment,
    OrderSidebarSendOrder,
    BFormCheckbox,
    BButton,
  },
  data() {
    return {
      signatureData: {
        isEmpty: true,
        data: null,
      },
    }
  },
  methods: {
    undo() {
      this.$refs.signaturePad.undoSignature()
    },
    save() {
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature()
      if (isEmpty) {
        window.alert('Please sign in the designated area')
      } else {
        this.signatureData = {
          isEmpty, data,
        }
      }
    },
  },
  // eslint-disable-next-line no-unused-vars
  setup(props) {
    const {
      decoratePaymentDetails,
    } = useOrderHandler()
    const toast = useToast()
    const { copy } = useClipboard()

    const copySignatureRequestLink = link => {
      copy(`${window.location.origin}${link}`)
      toast({
        component: ToastificationContent,
        props: {
          title: 'Link Copied',
          icon: 'CopyIcon',
          variant: 'success',
        },
      })
    }

    const orderData = ref(null)
    const showSignatureControl = ref(false)
    const INVOICE_APP_STORE_MODULE_NAME = 'app-order'

    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.registerModule(INVOICE_APP_STORE_MODULE_NAME, OrderStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME)
    })

    store.dispatch('app-order/fetchOrder', {
      id: router.currentRoute.params.id,
      query: router.currentRoute.query,
    })
      .then(response => {
        /* eslint-disable no-param-reassign */
        response.data.paymentDetails = decoratePaymentDetails(response.data.paymentDetails)
        /* eslint-enable no-param-reassign */
        orderData.value = response.data
      })
      .catch(e => {
        console.log(e)
        orderData.value = undefined
      })

    const printOrder = () => {
      window.print()
    }

    /**
     * if in client preview mode, hide the menus
     */
    if (router.currentRoute.query.showSignatureControl === 'true') {
      showSignatureControl.value = router.currentRoute.query.showSignatureControl === 'true'
      store.commit('appConfig/UPDATE_NAVBAR_CONFIG', { type: 'hidden' })
      store.commit('appConfig/UPDATE_FOOTER_CONFIG', { type: 'hidden' })
      store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', true)
    }

    const signOrder = signatureData => {
      if (!signatureData || (signatureData && signatureData.isEmpty)) {
        window.alert('Please sign in the designated area')
        return
      }

      const signaturePayload = {
        isSigned: true,
        signedDate: new Date(),
        signedBy: signatureData.data,
      }

      store.dispatch('app-order/signOrder', { id: router.currentRoute.params.id, payload: signaturePayload })
        .then(response => {
          window.alert('Thank you for confirming the order')
          decoratePaymentDetails(response.data.paymentDetails)
          orderData.value = response.data
        })
        .catch(() => {
          window.alert('Unexpected error, unable to finishing signing/confirming the order, please contact customer service')
        })
    }

    const showControl = false
    const agreed = false

    return {
      orderData,
      showSignatureControl,
      printOrder,
      signOrder,
      agreed,
      showControl,
      copySignatureRequestLink,
    }
  },
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-order.scss";
</style>

<style lang="scss">
@media print {

  // Global Styles
  body {
    background-color: transparent !important;
  }
  nav.header-navbar {
    display: none;
  }
  .main-menu {
    display: none;
  }
  .header-navbar-shadow {
    display: none !important;
  }
  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }
  footer.footer {
    display: none;
  }
  .card {
    background-color: transparent;
    box-shadow: none;
  }
  .customizer-toggle {
    display: none !important;
  }

  // Order Specific Styles
  .order-preview-wrapper {
    .row.order-preview {
      .col-md-8 {
        max-width: 100%;
        flex-grow: 1;
      }

      .order-preview-card {
        .card-body:nth-of-type(2) {
          .row {
              > .col-12 {
              max-width: 50% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }

    // Action Right Col
    .order-actions {
      display: none;
    }
  }
}
</style>
