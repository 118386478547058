<template>
  <b-img
    :src="appLogoImage"
    style="height:80px;"
  />
</template>
<script>
import { $themeConfig } from '@themeConfig'
import { BImg } from 'bootstrap-vue'

export default {
  components: {
    BImg,
  },
  setup() {
    const { appLogoImage } = $themeConfig.app
    return {
      appLogoImage,
    }
  },
}
</script>
