<template>
  <div>
    <div class="logo-wrapper">
      <logo />
    </div>
    <b-card-text class="mb-25">
      {{ orgContact.address }}
    </b-card-text>
    <b-card-text class="mb-25">
      {{ `${orgContact.city}, ${orgContact.province} ${orgContact.postalCode}` }}
    </b-card-text>
    <b-card-text class="mb-0">
      {{ orgContact.phoneNumber }}
    </b-card-text>
    <b-card-text class="mb-0">
      {{ orgContact.email }}
    </b-card-text>
  </div>
</template>
<script>
import { $themeConfig } from '@themeConfig'
import { BCardText } from 'bootstrap-vue'
import Logo from '@core/layouts/components/Logo.vue'

export default {
  components: {
    BCardText,
    Logo,
  },
  setup() {
    const orgContact = $themeConfig.org.contact
    return {
      orgContact,
    }
  },
}
</script>
